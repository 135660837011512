import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Payment from "./pages/payment";
import MultiCart from "./pages/multi-cart";

const CHECKOUT_SERVICE = "/payment/service/:serviceName/*";
const CHECKOUT_INVOICE = "/payment/invoice/:invoiceId/*";
const SHOPPING_CART = "/cart";

const paymentPageTitles = {
  cart: "Shopping Cart",
  wallet: "Payment Wallet",
  "customer-information": "Customer Information",
  confirmation: "Payment Confirmation",
  "expired-session": "Expired Session"
};

const UUID_REGEX_TEST = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

const formatAgencyName = slug => {
  // slug is a string, value like "animal-shelter"
  // "animal-shelter" -> ["animal", "shelter"] (filter excludes "" in the case of trailing hyphen)
  const splitName = slug.split("-").filter(item => item !== "");
  const capitalName = splitName
    .map(string => `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`)
    .join(" ");
  // ["animal", "shelter"] -> ["Animal", "Shelter"] -> "Animal Shelter"
  return capitalName;
};

const formatPageTitle = contactName => {
  const location = window.location.pathname; // "/payment/service/animal-shelter/amount"
  const path = location.split("/"); // ["", "payment", "service", "animal-shelter", "amount"]
  const checkoutSlug = path?.[3] ?? ""; // "animal-shelter", UUID (invoices), "" if undefined
  const agency = UUID_REGEX_TEST.test(checkoutSlug) // "" if value is UUID or "Animal Shelter"
    ? ""
    : formatAgencyName(checkoutSlug);
  const subpage = paymentPageTitles?.[path?.[4]] ?? ""; // "amount" -> "Payment Amount" or "" if undefined (could happen if we add a new subpage to checkout and forget to update lines 13-18 above)
  const formattedName = [`${contactName} Payment Portal`, agency, subpage].join(
    " | "
  ); // ex "Denver Payment Portal | Animal Shelter | Payment Amount" or "Austin Payment Portal | Confirmation" (invoice)
  return formattedName;
};

const RouteHelmet = ({
  metadata,
  useContactName,
  title,
  screendoorForm,
  projectIds
}) => {
  const { projectId } = useParams();
  return (
    <Helmet
      onChangeClientState={
        screendoorForm
          ? (_, addedTags) =>
              injectScreendoorScript(projectIds[projectId], addedTags)
          : () => {}
      }
    >
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="06eaff8cb96bf38a2c4ccaa8f1e90291"
          src="//code.jquery.com/jquery-2.2.3.min.js"
        ></script>
      )}
      {screendoorForm && (
        <link
          href="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.css"
          rel="stylesheet"
        />
      )}
      {screendoorForm && (
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        />
      )}
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="06eaff8cb96bf38a2c4ccaa8f1e90291"
          src="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.js"
        ></script>
      )}
      <title>
        {useContactName ? `${formatPageTitle(metadata?.contactName)}` : title}
      </title>
      <link rel="shortcut icon" href={metadata?.favicon ?? ""} />
    </Helmet>
  );
};

const CheckoutPageContainer = ({
  onLogout,
  isLoggedIn,
  walletEnabled,
  children
}) => (
  <Fragment>
    <Header
      key="main-header"
      isLoggedIn={isLoggedIn}
      onLogout={onLogout}
      walletEnabled={walletEnabled}
    />
    {children}
    <Footer key="main-footer" showLogout={isLoggedIn} onLogout={onLogout} />
  </Fragment>
);
export const routes = {
  [CHECKOUT_SERVICE]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.subClientMetadata?.data || props.clientMetadata?.data}
        useContactName
        title="Payment Portal"
      />
      <CheckoutPageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        walletEnabled={props.walletEnabled}
      >
        <Payment {...props} />
      </CheckoutPageContainer>
    </Fragment>
  ),
  [CHECKOUT_INVOICE]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.subClientMetadata?.data || props.clientMetadata?.data}
        useContactName
        title="Payment Portal"
      />
      <CheckoutPageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        walletEnabled={props.walletEnabled}
      >
        <Payment {...props} />
      </CheckoutPageContainer>
    </Fragment>
  ),
  [SHOPPING_CART]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.subClientMetadata?.data || props.clientMetadata?.data}
        useContactName
        title="Shopping Cart"
      />
      <CheckoutPageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        walletEnabled={false}
      >
        <MultiCart {...props} />
      </CheckoutPageContainer>
    </Fragment>
  )
};
